import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import SocketPlugin from "@/plugins/SocketPlugin";
import ReloadRoutePlugin from "@/plugins/ReloadRoutePlugin";
import {plugin} from "@niftybit/vue";
import axios from "axios";
import localization from "@/localization";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import initializer from "@/initializer";

axios.defaults.baseURL = "https://" + location.host + "/api/frontend";
axios.defaults.headers.common['Accept'] = "application/json";
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let app = createApp(App);

app.use(SocketPlugin);
app.use(ReloadRoutePlugin);

app.use(plugin);

app.mixin({
    methods: {
        translate(key) {
            return localization.translate(key);
        }
    },
});

app.config.unwrapInjectedRef = true; // #TODO remove when updating to vue 3.3

if (import.meta.env.MODE === "staging" || import.meta.env.MODE === "production") {
    Sentry.init({
        app,
        dsn: "https://ba7b8ab470434b47abe97fe14555e08e@o4504303857041408.ingest.sentry.io/4504852556480512",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ["localhost", /^\//],
            }),
        ],
        tracesSampleRate: 0.05
    });
}

app.use(router).mount('#app');

initializer.initialize();