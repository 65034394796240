import {modules} from "@/systems/modules/modules";
import authorization from "@/authorization";
import BansView from "@/systems/modules/punishments/BansView.vue";
import PunishmentsHeaderView from "@/systems/modules/punishments/PunishmentsHeaderView.vue";
import KicksView from "@/systems/modules/punishments/KicksView.vue";
import WarningsView from "@/systems/modules/punishments/WarningsView.vue";

export default {
    initialize() {

    },
    getRoutes: () => ([
        {
            name: "modules.punishments",
            path: "punishments",
            component: PunishmentsHeaderView,
            meta: {
                authorize: () => authorization.moduleEnabled(modules.punishments) && authorization.authenticated(),
            },
            redirect: { name: "modules.punishments.bans" },
            children: [
                {
                    name: "modules.punishments.bans",
                    path: "bans",
                    component: BansView
                },
                {
                    name: "modules.punishments.warnings",
                    path: "warnings",
                    component: WarningsView
                },
                {
                    name: "modules.punishments.kicks",
                    path: "kicks",
                    component: KicksView
                }
            ]
        },
    ])
};