export const modules = Object.freeze({
    core: "core",
    players: "players",
    servers: "servers",
    roles: "roles",
    settings: "settings",
    allowlist: "allowlist",
    punishments: "punishments",
    automation: "automation",
    blog: "blog",
    support: "support",
    analytics: "analytics",
    applications: "applications",
    auditLogs: "auditLogs",
    wiki: "wiki"
});