<template>
  <div>
    <div v-if="info.getModuleInfo(modules.applications)?.banned">
      <div class="alert alert-warning mb-4">
        <div class="d-flex align-items-center g-3">
          <i class="fas fa-ban text-warning"></i>
          <span>{{ translate('applications.banned.explanation') }}</span>
        </div>
      </div>
    </div>
    <CollectionView endpoint="modules/applications/applications" v-model:reload="reload">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between g-4">
          <h4 class="mb-0">{{ translate("applications") }}</h4>
          <ButtonView v-if="info.getModuleInfo(modules.applications)?.applicableCategories?.length > 0" type="normal" color="primary" :route="{ name: 'modules.applications.applications.create' }">
            {{ translate("create") }}
          </ButtonView>
        </div>
      </template>
      <template v-slot:content="{ items }">
        <div class="card">
          <div class="table-responsive">
            <div class="table">
              <div class="table-header">
                <div class="table-header-cell">{{ translate("category") }}</div>
                <div class="table-header-cell">{{ translate("status") }}</div>
                <div class="table-header-cell">{{ translate("created_at") }}</div>
                <div class="table-header-cell">{{ translate("replied_at") }}</div>
              </div>
              <div class="table-body">
                <a class="table-row" v-for="item in items" :key="item.id" @click="viewId = item.id">
                  <div class="table-column">
                    <div class="d-flex align-items-center">
                      <i class="fas fa-tag fa-lg me-2" :style="{ color: item.category.color }"></i> <span>{{ item.category.name }}</span>
                    </div>
                  </div>
                  <div class="table-column" :class="{ 'text-danger': item.status.id === 3, 'text-success': item.status.id === 2, 'text-info': item.status.id === 1 }">{{ item.status.name }}</div>
                  <div class="table-column">{{ utilities.formatDatetime(item.createdAt) }}</div>
                  <div class="table-column">{{ item.repliedAt ? utilities.formatDatetime(item.repliedAt) : "" }}</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CollectionView>
    <ModalView :active="viewId !== undefined" @update:active="viewId = undefined" size="large">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between g-4">
          <h3 class="mb-0">{{ translate("application") }}</h3>
          <ModalCloseButtonView @click="viewId = undefined"/>
        </div>
      </template>
      <template v-slot:body>
        <ApplicationView :application-id="viewId"/>
      </template>
    </ModalView>
  </div>
</template>

<script setup>
import {
  CollectionView,
  utilities,
  ButtonView,
  ModalView,
  ModalCloseButtonView
} from "@niftybit/vue";
import ApplicationView from "@/systems/modules/applications/ApplicationView.vue";
import {ref} from "vue";
import info from "@/info";
import {modules} from "@/systems/modules/modules";

const reload = ref(false);

const viewId = utilities.routeQueryParam("viewId");

</script>