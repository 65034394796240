import {modules} from "@/systems/modules/modules";
import authorization from "@/authorization";
import {RouterView} from "vue-router";
import TicketsView from "@/systems/modules/support/TicketsView.vue";
import CreateTicketView from "@/systems/modules/support/CreateTicketView.vue";

export default {
    initialize() {

    },
    getRoutes: () => ([
        {
            name: "modules.support",
            path: "support",
            component: RouterView,
            meta: {
                authorize: () => authorization.moduleEnabled(modules.support) && authorization.authenticated(),
            },
            redirect: { name: "modules.support.tickets" },
            children: [
                {
                    name: "modules.support.tickets",
                    path: "list",
                    component: TicketsView
                },
                {
                    name: "modules.support.tickets.create",
                    path: "create",
                    component: CreateTicketView
                }
            ]
        },
    ])
};