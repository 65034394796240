import {reactive} from "vue";
import eventBus from "@/eventBus";
import axios from "axios";
import {toasts} from "@niftybit/vue";
import router from "@/router";

export default reactive({
    list: [],
    unread: false,
    loading: false,
    total: undefined,
    initialize() {
        eventBus.on("login", () => {
            this.list = [];
            this.load();
            this.loadUnreadStatus();
        });
        eventBus.on("logout", () => {
            this.list = [];
        });
        eventBus.on("socket:event", data => {
            this.onSocketEvent(data);
        });
    },
    read(id) {
        const item = this.list.find(item => item.id === id);
        if (!item || item.read) return;
        axios.post("notifications/" + id + "/read").then(() => {
            if (item) item.read = true;
            this.loadUnreadStatus();
        });
    },
    loadUnreadStatus() {
        axios.get("notifications/unread/status").then(response => {
            this.unread = response.data.unread;
        });
    },
    reload() {
        this.list = [];
        this.load();
    },
    load() {
        if (this.loading || this.total !== undefined && this.list.length >= this.total) return;
        this.loading = true;
        axios.get("notifications", { params: { offset: this.list.length, limit: 20, order: "createdAt:desc" } }).then(response => {
            this.list.push(...response.data.data);
            this.total = response.data.total;
        }).catch(error => {
            toasts.create({
                color: "danger",
                content: error?.response?.data?.message ? error.response.data.message : error.message
            });
        }).finally(() => this.loading = false);
    },
    routeExists(route) {
        try {
            return router.resolve(route) != null;
        } catch {
            return false;
        }
    },
    onSocketEvent(data) {
        if (data.event === "notification") {
            if (!this.unread) this.unread = true;
            const that = this;
            toasts.create({
                color: "primary",
                content: data.data.content,
                click() {
                    if (data.data.route && that.routeExists(data.data.route)) {
                        router.push(data.data.route);
                    }
                    that.read(data.data.id);
                }
            });
            this.reload();
        }
    },
});