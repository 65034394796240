<template>
  <div>
    <HttpContentView :endpoint="utilities.path('modules/allowlist/players/@/status', info.player.id)" v-model:reload="reload">
      <template v-slot:content="{ data: entryData }">
        <div class="card mb-4">
          <div class="card-body">
            <div class="row align-items-center justify-content-between g-4">
              <div class="col-12 col-md-6">
                <h3 v-if="entryData.entry" class="d-flex align-items-center g-3 mb-0">
                  <i class="fas fa-check-circle text-success" style="font-size: 1.2em"></i>
                  <span>{{ translate("allowlisted") }}</span>
                </h3>
                <h3 v-else class="d-flex align-items-center g-3 mb-0">
                  <i class="fas fa-xmark-circle text-warning" style="font-size: 1.2em"></i>
                  <span>{{ translate("allowlist.not.allowlisted") }}</span>
                </h3>
              </div>
              <div class="col-12 col-md-6">
                <ButtonView v-if="!entryData.entry && !entryData.pendingApplication" type="normal" color="primary" class="w-100" @click="applying = true">
                  {{ translate("create.application") }}
                </ButtonView>
              </div>
            </div>
          </div>
        </div>
      </template>
    </HttpContentView>
    <CollectionView :endpoint="utilities.path('modules/allowlist/players/@/applications', info.player.id)" v-model:reload="reload">
      <template v-slot:header>
        <h4 class="mb-0">{{ translate("allowlist.applications") }}</h4>
      </template>
      <template v-slot:content="{ items }">
        <div class="card">
          <div class="table-responsive">
            <div class="table">
              <div class="table-header">
                <div class="table-header-cell">{{ translate("status") }}</div>
                <div class="table-header-cell">{{ translate("created_at") }}</div>
                <div class="table-header-cell">{{ translate("replied_at") }}</div>
                <div class="table-header-cell">{{ translate("actions") }}</div>
              </div>
              <div class="table-body">
                <div class="table-row" v-for="item in items" :key="item.id">
                  <div class="table-column" :class="{ 'text-danger': item.status.id === 3, 'text-success': item.status.id === 2, 'text-info': item.status.id === 1 }">{{ item.status.name }}</div>
                  <div class="table-column">{{ utilities.formatDatetime(item.createdAt) }}</div>
                  <div class="table-column">{{ item.repliedAt ? utilities.formatDatetime(item.repliedAt) : "" }}</div>
                  <div class="table-column">
                    <div class="d-flex align-items-center g-2">
                      <ButtonView type="soft" color="primary" size="small" @click="viewApplicationId = item.id">
                        <i class="fas fa-eye"></i>
                      </ButtonView>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CollectionView>
    <ModalView :active="viewApplicationId !== undefined" @update:active="viewApplicationId = undefined" size="large">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between g-4">
          <h3 class="mb-0">{{ translate("allowlist.application") }}</h3>
          <ModalCloseButtonView @click="viewApplicationId = undefined"/>
        </div>
      </template>
      <template v-slot:body>
        <HttpContentView :endpoint="utilities.path('modules/allowlist/applications/@', viewApplicationId)" v-model:reload="reload">
          <template v-slot:content="{ data: application }">
            <div class="d-flex flex-column g-4">
              <div class="row align-items-center g-3">
                <div class="col-6">
                  <h4 class="mb-1">{{ translate('status') }}</h4>
                  <span :class="{ 'text-danger': application.status.id === 3, 'text-success': application.status.id === 2, 'text-info': application.status.id === 1 }">{{ application.status.name }}</span>
                </div>
                <div class="col-6">
                  <h4 class="mb-1">{{ translate('created_at') }}</h4>
                  <span class="text-muted text-break">
                    {{ utilities.formatDatetime(application.createdAt) }}
                  </span>
                </div>
              </div>
              <div v-if="application.answers" class="list-group">
                <div class="list-group-item" v-for="answer in application.answers" :key="answer.id">
                  <h4 class="text-white mb-2" v-if="answer.question">{{ answer.question.content }}</h4>
                  <h4 class="text-white mb-2" v-else><del>{{ translate("deleted_question") }}</del></h4>
                  <span class="small text-light" style="white-space: pre-line; word-break: break-word">{{ answer.content }}</span>
                </div>
              </div>
              <div v-if="application.replied" class="card">
                <div class="card-body">
                  <h3>{{ translate("answer") }}</h3>
                  <div v-if="application.reply" class="mt-1 small" style="white-space: pre-line; word-break: break-word">{{ application.reply }}</div>
                  <div class="d-flex justify-content-between align-items-center g-3 mt-3 small">
                    <UserView v-if="application.replier" :user="application.replier" class="d-inline-block"/>
                    <span class="text-break">{{ utilities.formatDatetime(application.repliedAt) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </HttpContentView>
      </template>
    </ModalView>
    <ModalView v-model:active="applying" size="large">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between g-4">
          <h3 class="mb-0">{{ translate("allowlist.application") }}</h3>
          <ModalCloseButtonView @click="applying = false"/>
        </div>
      </template>
      <template v-slot:body>
        <HttpFormView :endpoint="utilities.path('modules/allowlist/communities/@/applications', info.community.id)" method="post" :content="{ answers: [] }" @success="applying = false; reload = true">
          <template v-slot:form="{ content, errors, submit, loading }">
            <HttpContentView :endpoint="utilities.path('modules/allowlist/communities/@/applications/questions', info.community.id)">
              <template v-slot:content="{ data: questions }">
                <div class="d-flex flex-column g-3">
                  <div v-for="(question, index) in questions" :key="question.id">
                    <InputView type="textarea" :max="1000" :title="question.content" :disabled="loading" :errors="errors?.answers ? errors.answers[index]?.content : undefined" required :value="content.answers.find(item => item.questionId === question.id)?.content" @update:value="content.answers.find(item => item.questionId === question.id) ? content.answers.find(item => item.questionId === question.id).content = $event : content.answers.push({ questionId: question.id, content: $event })"/>
                  </div>
                  <div class="d-flex align-items-center g-4">
                    <ButtonView type="normal" color="primary" class="w-100" @click="submit" :disabled="!content.answers || content.answers.length < questions.length">
                      {{ translate("submit") }}
                      <SpinnerView color="primary" :active="loading"/>
                    </ButtonView>
                    <ButtonView type="normal" color="secondary" class="w-100" @click="applying = false">
                      {{ translate("cancel") }}
                    </ButtonView>
                  </div>
                </div>
              </template>
            </HttpContentView>
          </template>
        </HttpFormView>
      </template>
    </ModalView>
  </div>
</template>

<script setup>
import {HttpContentView, utilities, CollectionView, HttpFormView, InputView, ModalView, ButtonView, SpinnerView, ModalCloseButtonView} from "@niftybit/vue";
import info from "@/info";
import {ref} from "vue";
import UserView from "@/components/other/UserView.vue";

const applying = ref(false);

const reload = ref(false);

const viewApplicationId = utilities.routeQueryParam("viewApplicationId");

</script>