import {state} from "@/state";
import router from "@/router";
import eventBus from "@/eventBus";

export default {
    install(app) {
        state.plugins.reloadRoute = {
            listeners: [],
            reloadRoute: data => {
                let current = router.currentRoute.value;
                if (!current) return;
                if (data.name !== current.name) return;
                if ((data.params?.length ?? 0) !== (current.params?.length ?? 0) || data.params && current.params && [...Object.keys(data.params), ...Object.keys(current.params)].find(key => String(data.params[key]) !== current.params[key])) return;
                if (data.query && ((data.query?.length ?? 0) !== (current.query?.length ?? 0) || data.query && current.query && [...Object.keys(data.query), ...Object.keys(current.query)].find(key => String(data.query[key]) !== current.query[key]))) return;
                state.plugins.reloadRoute.listeners.forEach(listener => listener());
            }
        };
        app.mixin({
            mounted() {
                if (!this.onRouteReload) return;
                state.plugins.reloadRoute.listeners.push(this.onRouteReload);
            },
            unmounted() {
                if (!this.onRouteReload) return;
                let index = state.plugins.reloadRoute.listeners.findIndex(listener => listener === this.onRouteReload);
                if (index === -1) return;
                state.plugins.reloadRoute.listeners.splice(index, 1);
            },
        });
        eventBus.on("socket:event", event => {
            if (event.event === "reloadRoute") {
                state.plugins.reloadRoute.reloadRoute(event.data);
            }
        });
    }
}
