<template>
  <div>
    <div v-if="info.statusCode !== undefined && info.statusCode !== 200">
      <div class="position-fixed d-flex align-items-center justify-content-center top-0 bottom-0 start-0 end-0">
        <div class="text-center large">
          <div v-if="info.statusCode === 404">
            <i class="fa-duotone fa-file-circle-question text-info fa-3x"></i>
            <div class="mt-5">No community found at address {{ hostname }}.</div>
          </div>
          <div v-else>
            <i class="fa-duotone fa-exclamation-circle text-warning fa-3x"></i>
            <div class="mt-5">Failed to load community ({{ info.statusCode }}). Please try to refresh the page.</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!initializer.initialized">
      <SpinnerView active color="primary" style="font-size: 3em"/>
    </div>
    <div v-else style="height: 100vh">
      <div class="container">
        <NavbarView/>
        <div class="row">
          <div v-if="newerVersionAvailable" class="col-12 mt-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center g-4">
                  <i class="fas fa-rotate text-primary" style="font-size: 1.8em"></i>
                  <span>{{ translate("frontendUpdate.description") }}</span>
                </div>
                <ButtonView type="normal" color="primary" class="w-100 mt-4" @click="performUpdate">
                  {{ translate('update') }}
                </ButtonView>
              </div>
            </div>
          </div>
        </div>
        <RouterView v-slot="{ Component }" class="py-4">
          <component :is="Component" />
        </RouterView>
      </div>
      <ToastsView/>
    </div>
  </div>
</template>

<script setup>
import {ToastsView, versionManager, ButtonView, SpinnerView, modal} from "@niftybit/vue";
import info from "@/info";
import {computed, onMounted, onUnmounted, watch} from "vue";
import eventBus from "@/eventBus";
import NavbarView from "@/components/global/NavbarView.vue";
import initializer from "@/initializer";

onMounted(() => {
  document.title = "";
  versionManager.initialize(location.hostname);
});

onUnmounted(() => {
  versionManager.deinitialize();
});

eventBus.on("info.loaded", () => {
  document.title = info.community?.name ?? "";
});

function performUpdate() {
  versionManager.performUpdate();
}

const hostname = location.hostname;
const scrollLock = computed(() => modal.count > 0);
const newerVersionAvailable = computed(() => versionManager.newerVersionAvailable !== undefined);

watch(scrollLock, () => {
  if (scrollLock.value) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
});
</script>