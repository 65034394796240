import {reactive} from "vue";
import axios from "axios";
import {toasts} from "@niftybit/vue";
import router from "@/router";
import eventBus from "@/eventBus";

export default reactive({
    loading: false,
    statusCode: undefined,
    community: undefined,
    player: undefined,
    getModuleInfo(module) {
        if (this.community?.modules) return this.community.modules[module];
        return undefined;
    },
    async reload() {
        try {
            const response = await axios.get("info");
            const newlyAuthenticated = !this.player && response.data.player;
            this.community = response.data ?? {};
            this.player = this.community.player;
            delete this.community.player;
            if (newlyAuthenticated) eventBus.emit("login");
            eventBus.emit("info.loaded");
            this.statusCode = 200;
        } catch (error) {
            toasts.create({
                color: "danger",
                content: "Failed to load info (status " + error.response?.status + ")."
            });
            this.statusCode = error.response?.status ?? 0;
            throw error;
        }
    },
    async initialize() {
        let reloadTimeout = undefined;
        let lastReload = Date.now();
        eventBus.on("logout", () => {
            this.player = undefined;
            router.push("/");
            this.reload();
        });
        eventBus.on("socket:event", data => {
            if (data.event === "reloadInfo") {
                if (Date.now() < lastReload + 800) {
                    if (reloadTimeout === undefined)  {
                        reloadTimeout = setTimeout(() => {
                            lastReload = Date.now();
                            this.reload();
                            reloadTimeout = undefined;
                        }, 800);
                    }
                } else {
                    lastReload = Date.now();
                    this.reload();
                }
            }
        });
        await this.reload();
        setInterval(() => {
            if (Date.now() > (lastReload + 30000)) {
                lastReload = Date.now();
                this.reload();
            }
        }, 60000);
    }
});