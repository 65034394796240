import {modules} from "@/systems/modules/modules";
import authorization from "@/authorization";
import BlogPostView from "@/systems/modules/blog/BlogPostView.vue";
import {RouterView} from "vue-router";
import BlogView from "@/systems/modules/blog/BlogView.vue";

export default {
    initialize() {

    },
    getRoutes: () => ([
        {
            name: "modules.blog",
            path: "blog",
            component: RouterView,
            meta: {
                authorize: () => authorization.moduleEnabled(modules.blog),
            },
            redirect: { name: "modules.blog.home" },
            children: [
                {
                    name: "modules.blog.home",
                    path: ":categorySlug?",
                    component: BlogView,
                    props: true
                },
                {
                    name: "modules.blog.post",
                    path: ":categorySlug/:postSlug",
                    component: BlogPostView,
                    props: true
                }
            ]
        },
    ])
};