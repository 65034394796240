<template>
  <div class="d-flex align-items-center justify-content-between g-2 py-2 flex-wrap position-sticky top-0" style="z-index: 1; background-color: rgba(var(--color-background), 0.9); backdrop-filter: blur(14px)">
    <ButtonView type="shaded" color="foreground" route="/" active-class="" class="me-auto">
      <div class="d-flex align-items-center g-2" style="white-space: nowrap">
        <img v-if="info.community.logoUrl" :src="info.community.logoUrl" class="rounded-1" style="max-height: 1.3em; width: 1.3em"/>
        <span class="fw-medium">{{ info.community.name }}</span>
      </div>
    </ButtonView>

    <div class="d-none d-lg-flex align-items-center g-1 flex-wrap">
      <ButtonView v-if="authorization.route({ name: 'modules.blog' })" type="shaded" color="foreground" :route="{ name: 'modules.blog' }">
        <div class="d-flex align-items-center g-2">
          <i class="fa-duotone fa-blog text-success"></i>
          <span>{{ translate("blog") }}</span>
        </div>
      </ButtonView>
      <ButtonView v-if="authorization.route({ name: 'modules.wiki' })" type="shaded" color="foreground" :route="{ name: 'modules.wiki' }">
        <div class="d-flex align-items-center g-2">
          <i class="fa-duotone fa-book text-primary"></i>
          <span>{{ translate("wiki") }}</span>
        </div>
      </ButtonView>
      <ButtonView v-if="authorization.route({ name: 'modules.allowlist' })" type="shaded" color="foreground" :route="{ name: 'modules.allowlist' }">
        <div class="d-flex align-items-center g-2">
          <i class="fa-duotone fa-list text-success"></i>
          <span>{{ translate("allowlist") }}</span>
        </div>
      </ButtonView>
      <ButtonView v-if="authorization.route({ name: 'modules.support' })" type="shaded" color="foreground" :route="{ name: 'modules.support' }">
        <div class="d-flex align-items-center g-2">
          <i class="fa-duotone fa-life-ring text-info"></i>
          <span>{{ translate("support") }}</span>
        </div>
      </ButtonView>
      <ButtonView v-if="authorization.route({ name: 'modules.applications' })" type="shaded" color="foreground" :route="{ name: 'modules.applications' }">
        <div class="d-flex align-items-center g-2">
          <i class="fa-duotone fa-file-contract text-success"></i>
          <span>{{ translate("applications") }}</span>
        </div>
      </ButtonView>
      <ButtonView v-if="authorization.route({ name: 'modules.punishments' })" type="shaded" color="foreground" :route="{ name: 'modules.punishments' }">
        <div class="d-flex align-items-center g-2">
          <i class="fa-duotone fa-gavel text-danger"></i>
          <span>{{ translate("modules.punishments") }}</span>
        </div>
      </ButtonView>

      <ButtonView v-if="info.community?.languages" type="shaded" color="foreground" @click="changingLanguage = true">
        <div class="position-relative">
          {{ info.community.languages.find(item => item.code === (info.community.language ?? localization.browserLanguage()))?.flag }}
        </div>
      </ButtonView>

      <div v-if="info.player" class="d-flex align-items-center g-1">
        <ButtonView type="shaded" color="foreground" @click="viewingNotifications = true">
          <div class="position-relative">
            <i class="fas fa-bell fa-fw"></i>
            <i v-if="notifications.unread" class="fas fa-circle text-danger fa-fw position-absolute top-0 end-0" style="font-size: 0.5em"></i>
          </div>
        </ButtonView>
        <ButtonView type="shaded" color="foreground" :route="{ name: 'players.me' }">
          <div class="d-flex align-items-center g-2 overflow-hidden">
            <AvatarView style="font-size: 1.2em;" :url="info.player.avatarUrl"/>
            <span class="overflow-hidden" style="white-space: nowrap; text-overflow: ellipsis">{{ info.player.name }}</span>
          </div>
        </ButtonView>
      </div>
      <div v-else>
        <ButtonView type="soft" color="primary" :route="{ name: 'authentication' }">
          <div class="d-flex align-items-center g-2">
            <i class="fa-duotone fa-right-to-bracket"></i>
            <span>{{ translate("login") }}</span>
          </div>
        </ButtonView>
      </div>
    </div>
    <ButtonView type="shaded" color="foreground" class="d-lg-none" @click="open = true">
      <i class="fas fa-bars fa-fw"></i>
    </ButtonView>
    <ModalView v-model:active="open">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between g-4">
          <ButtonView type="shaded" color="foreground" route="/" @click="open = false" active-class="" class="me-auto">
            <div class="d-flex align-items-center g-2" style="white-space: nowrap">
              <img v-if="info.community.logoUrl" :src="info.community.logoUrl" class="rounded-1" style="max-height: 1.3em; width: 1.3em"/>
              <span class="fw-medium">{{ info.community.name }}</span>
            </div>
          </ButtonView>
          <ModalCloseButtonView @click="open = false"/>
        </div>
      </template>
      <template v-slot:body>
        <div class="list-group">
          <RouterLink v-if="authorization.route({ name: 'modules.blog' })" active-class="active" :to="{ name: 'modules.blog' }" class="list-group-item list-group-item-action" @click="open = false">
            <div class="d-flex align-items-center g-2">
              <i class="fa-duotone fa-blog text-success"></i>
              <span>{{ translate("blog") }}</span>
            </div>
          </RouterLink>

          <RouterLink v-if="authorization.route({ name: 'modules.wiki' })" active-class="active" :to="{ name: 'modules.wiki' }" class="list-group-item list-group-item-action" @click="open = false">
            <div class="d-flex align-items-center g-2">
              <i class="fa-duotone fa-book text-primary"></i>
              <span>{{ translate("wiki") }}</span>
            </div>
          </RouterLink>
          <RouterLink v-if="authorization.route({ name: 'modules.allowlist' })" active-class="active" :to="{ name: 'modules.allowlist' }" class="list-group-item list-group-item-action" @click="open = false">
            <div class="d-flex align-items-center g-2">
              <i class="fa-duotone fa-list text-success"></i>
              <span>{{ translate("allowlist") }}</span>
            </div>
          </RouterLink>
          <RouterLink v-if="authorization.route({ name: 'modules.support' })" active-class="active" :to="{ name: 'modules.support' }" class="list-group-item list-group-item-action" @click="open = false">
            <div class="d-flex align-items-center g-2">
              <i class="fa-duotone fa-life-ring text-info"></i>
              <span>{{ translate("support") }}</span>
            </div>
          </RouterLink>
          <RouterLink v-if="authorization.route({ name: 'modules.applications' })" active-class="active" :to="{ name: 'modules.applications' }" class="list-group-item list-group-item-action" @click="open = false">
            <div class="d-flex align-items-center g-2">
              <i class="fa-duotone fa-file-contract text-success"></i>
              <span>{{ translate("applications") }}</span>
            </div>
          </RouterLink>
          <RouterLink v-if="authorization.route({ name: 'modules.punishments' })" active-class="active" :to="{ name: 'modules.punishments' }" class="list-group-item list-group-item-action" @click="open = false">
            <div class="d-flex align-items-center g-2">
              <i class="fa-duotone fa-gavel text-danger"></i>
              <span>{{ translate("punishments") }}</span>
            </div>
          </RouterLink>
        </div>
        <div class="d-flex align-items-center g-2 mt-4">
          <ButtonView v-if="info.player" type="shaded" color="foreground" class="w-100" :route="{ name: 'players.me' }" @click="open = false">
            <div class="d-flex align-items-center g-2 overflow-hidden">
              <AvatarView style="font-size: 1.2em;"/>
              <span class="overflow-hidden" style="white-space: nowrap; text-overflow: ellipsis">{{ info.player.name }}</span>
            </div>
          </ButtonView>
          <ButtonView v-else type="normal" color="primary" class="w-100" :route="{ name: 'authentication' }" @click="open = false">
            <div class="d-flex align-items-center g-2">
              <i class="fa-duotone fa-right-to-bracket"></i>
              <span>{{ translate("login") }}</span>
            </div>
          </ButtonView>

          <ButtonView v-if="info.community?.languages" type="shaded" color="foreground" @click="changingLanguage = true">
            <div class="position-relative">
              {{ info.community.languages.find(item => item.code === (info.community.language ?? localization.browserLanguage()))?.flag }}
            </div>
          </ButtonView>
        </div>
      </template>
    </ModalView>
    <ModalView v-model:active="viewingNotifications">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="mb-0">{{ translate("notifications") }}</h3>
          <div class="d-flex align-items-center g-3">
            <HttpFormView v-if="notifications.unread" endpoint="notifications/read" method="post" @success="notifications.reload(); notifications.unread = false">
              <template v-slot:form="{ submit, loading }">
                <ButtonView type="soft" color="primary" size="small" @click="submit">
                  {{ translate("notifications.mark_all_read") }}
                </ButtonView>
                <SpinnerView color="primary" :active="loading"/>
              </template>
            </HttpFormView>
            <ModalCloseButtonView @click="viewingNotifications = false"/>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <NotificationsView @dismiss="viewingNotifications = false"/>
      </template>
    </ModalView>
    <ModalView v-model:active="changingLanguage" size="small">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between g-4">
          <h3 class="mb-0">{{ translate("language") }}</h3>
          <ModalCloseButtonView @click="changingLanguage = false"/>
        </div>
      </template>
      <template v-slot:body>
        <HttpFormView :endpoint="info.player ? utilities.path('players/@/language', info.player.id) : 'session/language'" method="post" :content="{ code: info.community.language ?? info.community.languages.find(item => item.code === localization.browserLanguage())?.code ?? 'en' }" @success="info.reload(); localization.reloadTranslations(); changingLanguage = false">
          <template v-slot:form="{ content, submit, loading }">
            <div class="list-group">
              <button v-for="language in info.community.languages" :key="language.code" class="list-group-item list-group-item-action" @click="content.code = language.code; submit()" :disabled="loading">
                <i v-if="language.code === (info.community.language ?? localization.browserLanguage())" class="fas fa-check-circle text-primary"></i>
                <i v-else class="far fa-circle text-primary"></i>
                {{ language.flag }} {{ language.name }}
              </button>
            </div>
            <SpinnerView color="primary" :active="loading"/>
          </template>
        </HttpFormView>
      </template>
    </ModalView>
  </div>
</template>

<script setup>
import {ButtonView, ModalCloseButtonView, ModalView, SpinnerView, HttpFormView, utilities} from "@niftybit/vue";
import authorization from "@/authorization";
import info from "@/info";
import AvatarView from "@/components/global/AvatarView.vue";
import {ref} from "vue";
import NotificationsView from "@/systems/notifications/NotificationsView.vue";
import notifications from "@/systems/notifications/notifications";
import localization from "@/localization";

const viewingNotifications = ref(false);
const changingLanguage = ref(false);
const open = ref(false);

</script>