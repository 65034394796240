<template>
  <div>
    <h1 class="d-flex align-items-center g-3 mb-4">
      <i class="fas fa-blog text-success"></i>
      {{ translate("blog") }}
    </h1>
    <HttpContentView :endpoint="utilities.path('communities/@/modules/blog/categories', communityId)">
      <template v-slot:content="{ data }">
        <div class="d-flex align-items-center g-2 flex-wrap mb-4">
          <ButtonView v-for="category in data" :key="category.id" type="normal" color="secondary" @click="categorySlug === category.slug ? categorySlug = undefined : categorySlug = category.slug" :active="categorySlug === category.slug">
            <div class="d-flex align-items-center g-2">
              <i class="fas fa-circle" :style="{ color: category.color }"></i>
              <span>{{ category.name }}</span>
            </div>
          </ButtonView>
        </div>
      </template>
    </HttpContentView>
    <CollectionView :endpoint="utilities.path('communities/@/modules/blog/posts', communityId)" :params="{ categorySlug }">
      <template v-slot:header>
        <h4 class="mb-0">{{ translate("posts") }}</h4>
      </template>
      <template v-slot:content="{ items }">
        <div class="row g-4">
          <div v-for="item in items" :key="item.id" class="col-12 col-md-6 col-lg-4">
            <RouterLink class="text-decoration-none text-foreground" :to="{ name: 'modules.blog.post', params: { categorySlug: item.category.slug, postSlug: item.slug }}">
              <div class="card clickable">
                <div class="card-body">
                  <div class="d-flex align-items-center g-2 small mb-2">
                    <i class="fas fa-circle" :style="{ color: item.category.color }"></i>
                    <span>{{ item.category.name }}</span>
                  </div>
                  <h3>{{ item.title }}</h3>
                  <div class="text-muted small">{{ utilities.formatDatetime(item.updatedAt ?? item.createdAt) }}</div>
                </div>
              </div>
            </RouterLink>
          </div>
        </div>
      </template>
    </CollectionView>
  </div>
</template>

<script setup>
import {ButtonView, HttpContentView, utilities, CollectionView} from "@niftybit/vue";
import info from "@/info";

const categorySlug = utilities.routePathParam("categorySlug");

const communityId = info.community.id;

</script>