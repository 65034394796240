<template>
  <div>
    <ButtonView type="normal" color="secondary" class="mb-4" active-class="" :route="{ name: 'modules.support.tickets' }">
      <div class="d-flex align-items-center g-2">
        <i class="fas fa-arrow-left fa-fw"></i>
        <span>{{ translate("tickets") }}</span>
      </div>
    </ButtonView>
    <div v-if="info.getModuleInfo(modules.support)?.banned">
      <div class="alert alert-warning">
        <div class="d-flex align-items-center g-3">
          <i class="fas fa-ban text-warning"></i>
          <span>{{ translate('support.banned.explanation') }}</span>
        </div>
      </div>
    </div>
    <HttpContentView v-else endpoint="modules/support/tickets/categories">
      <template v-slot:content="{ data: categories }">
        <HttpFormView endpoint="modules/support/tickets" :content="{ categoryId: categoryId }" method="post" @success="router.push({ name: 'modules.support.tickets', query: { viewId: $event.id }})">
          <template v-slot:form="{ content, errors, submit, loading }">
            <div class="row g-4">
              <div class="col-12 col-md-6 col-lg-4" v-for="category in categories" :key="category.id">
                <div class="card clickable" :class="{ 'border-primary': content.categoryId === category.id }" @click="content.categoryId = category.id; nextTick(() => formElement.scrollIntoView())">
                  <div class="card-body">
                    <h3 class="d-flex align-items-center g-2 mb-0">
                      <i class="fas fa-tag" :style="{ color: category.color }"></i> <span class="fw-bold">{{ category.name }}</span>
                    </h3>
                    <div v-if="category.description" class="mt-3 small">{{ category.description }}</div>
                  </div>
                </div>
              </div>
            </div>
            <InputErrorsView :errors="errors.categoryId"/>
            <div class="card mt-4" v-if="content.categoryId" ref="formElement">
              <div class="card-body">
                <div class="d-flex flex-column g-4">
                  <InputView type="text" :max="100" :title="translate('subject')" :errors="errors.subject" required v-model:value="content.subject"/>
                  <InputView type="textarea" :max="2000" :title="translate('message')" :errors="errors.message" required v-model:value="content.message"/>
                  <HttpSelectInputView endpoint="modules/support/tickets/players/search" v-model:value="content.participantIds" multiple :max="6" :title="translate('participants')" :description="translate('tickets.participants.description')" :errors="errors.participants">
                    <template v-slot:option="{ option, preview }">
                      <span v-if="preview" class="bg-secondary rounded-2" style="padding: 0.05em 0.3em; font-size: 0.9em">{{ option.title }}</span>
                      <span v-else>{{ option.title }}</span>
                    </template>
                  </HttpSelectInputView>
                  <ButtonView type="normal" color="primary" class="w-100" @click="submit()" :disabled="loading">
                    {{ translate("create") }}
                  </ButtonView>
                </div>
              </div>
            </div>
            <SpinnerView :active="loading" color="primary"/>
          </template>
        </HttpFormView>
      </template>
    </HttpContentView>
  </div>
</template>

<script setup>
import {
  ButtonView,
  HttpContentView,
  HttpFormView,
  HttpSelectInputView,
  InputView,
  InputErrorsView,
  SpinnerView
} from "@niftybit/vue";
import info from "@/info";
import {modules} from "@/systems/modules/modules";
import {nextTick, ref} from "vue";
import {useRouter} from "vue-router";

const categoryId = ref(undefined);

const formElement = ref(undefined);

const router = useRouter();

</script>