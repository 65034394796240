import navigation from "@/navigation";
import info from "@/info";

export default {
    route(route) {
        return navigation.isRouteAuthorized(route);
    },
    moduleEnabled(module) {
        return info.community?.enabledModules?.includes(module) === true;
    },
    featureEnabled(feature) {
        if (!info.community) return false;
        return Object.values(info.community.modules).find(module => module.features.includes(feature)) !== undefined;
    },
    authenticated() {
        return info.player != null;
    }
};