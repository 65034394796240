<template>
  <div>
    <CollectionView endpoint="modules/punishments/kicks" v-model:reload="reload" default-order="createdAt:desc">
      <template v-slot:header>
        <h4 class="mb-0">{{ translate("punishments.kicks") }}</h4>
      </template>
      <template v-slot:content="{ items }">
        <div class="card">
          <div class="table-responsive">
            <div class="table">
              <div class="table-header">
                <div class="table-header-cell">{{ translate("reason") }}</div>
                <div class="table-header-cell">{{ translate("creator") }}</div>
                <div class="table-header-cell">{{ translate("createdAt") }}</div>
              </div>
              <div class="table-body">
                <div class="table-row" v-for="item in items" :key="item.id">
                  <div class="table-column">
                    {{ item.reason }}
                  </div>
                  <div class="table-column">
                    <UserView :user="item.creator"/>
                  </div>
                  <div class="table-column">
                    {{ utilities.formatDatetime(item.createdAt) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CollectionView>
  </div>
</template>

<script setup>
import {CollectionView, utilities} from "@niftybit/vue";
import {ref} from "vue";
import UserView from "@/components/other/UserView.vue";

const reload = ref(false);

</script>