<template>
  <div>
    <CollectionView :endpoint="utilities.path('modules/punishments/players/@/warnings', info.player.id)" v-model:reload="reload" default-order="createdAt:desc">
      <template v-slot:header>
        <h4 class="mb-0">{{ translate("warnings") }}</h4>
      </template>
      <template v-slot:content="{ items }">
        <div class="card">
          <div class="table-responsive">
            <div class="table">
              <div class="table-header">
                <div class="table-header-cell">{{ translate("reason") }}</div>
                <div class="table-header-cell">{{ translate("active") }}</div>
                <div class="table-header-cell">{{ translate("duration") }}</div>
                <div class="table-header-cell">{{ translate("createdAt") }}</div>
                <div class="table-header-cell">{{ translate("expiresAt") }}</div>
                <div class="table-header-cell">{{ translate("actions") }}</div>
              </div>
              <div class="table-body">
                <div class="table-row" v-for="item in items" :key="item.id">
                  <div class="table-column">
                    {{ item.reason }}
                  </div>
                  <div class="table-column">
                    <i v-if="!item.expiresAt || DateTime.fromISO(item.expiresAt).diff(DateTime.now()) > 0" class="fas fa-check-circle text-success"></i>
                    <i v-else class="fas fa-xmark-circle text-muted"></i>
                  </div>
                  <div class="table-column">
                    {{ item.duration ? utilities.formatDuration(item.duration, true) : translate("permanent") }}
                  </div>
                  <div class="table-column">
                    {{ utilities.formatDatetime(item.createdAt) }}
                  </div>
                  <div class="table-column">
                    {{ item.expiresAt ? utilities.formatDatetime(item.expiresAt) : translate("never") }}
                  </div>
                  <div class="table-column">
                    <div class="d-flex align-items-center g-2">
                      <ButtonView type="soft" color="primary" size="small" @click="viewingId = item.id">
                        <i class="fas fa-eye"></i>
                      </ButtonView>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CollectionView>
    <ModalView :active="viewingId !== undefined" @update:active="viewingId = undefined">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between g-4">
          <h3 class="mb-0">{{ translate("warning") }}</h3>
          <ModalCloseButtonView @click="viewingId = undefined"/>
        </div>
      </template>
      <template v-slot:body>
        <WarningView :id="viewingId"/>
      </template>
    </ModalView>
  </div>
</template>

<script setup>
import {CollectionView, ModalView, utilities, ButtonView, ModalCloseButtonView} from "@niftybit/vue";
import {ref} from "vue";
import info from "@/info";
import {DateTime} from "luxon";
import WarningView from "@/systems/modules/punishments/WarningView.vue";

const reload = ref(false);
const viewingId = ref(undefined);

</script>