<template>
  <div>
    <HttpContentView :endpoint="utilities.path('modules/support/tickets/@', ticketId)" v-model:reload="reload" @response="scrollToBottom">
      <template v-slot:content="{ data: ticket }">
        <div class="row g-4">
          <div class="col-6">
            <h5 class="mb-2">{{ translate("subject") }}</h5>
            <div>{{ ticket.subject }}</div>
          </div>
          <div class="col-6">
            <h5 class="mb-2">{{ translate("category") }}</h5>
            <div class="d-flex">
              <span class="d-flex align-items-center g-2">
                <i class="fas fa-tag" :style="{ color: ticket.category.color }"></i> <span>{{ ticket.category.name }}</span>
              </span>
            </div>
          </div>
          <div class="col-6">
            <h5 class="mb-2">{{ translate("status") }}</h5>
            <div class="d-flex align-items-center g-2">
              <i class="fas fa-circle" :class="{ 'text-danger': ticket.status.id === 3, 'text-success': ticket.status.id === 2, 'text-info': ticket.status.id === 1 }"></i>
              <span>{{ ticket.status.title }}</span>
            </div>
          </div>
          <div class="col-6">
            <h5 class="mb-2">{{ translate("created_at") }}</h5>
            <div>{{ utilities.formatDatetime(ticket.createdAt) }}</div>
          </div>
          <div class="col-6" v-if="ticket.status.id !== 4 || ticket.participants && ticket.participants.length > 0">
            <h5 class="mb-2">{{ translate("participants") }}</h5>
            <div class="d-flex align-items-center g-3 flex-wrap">
              <div v-for="participant in ticket.participants" :key="participant.id">
                <PlayerView :player="participant" style="white-space: nowrap"/>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div>
            <div v-for="(message, index) in ticket.messages.map(item => ({...item, date: utilities.parseISODate(item.createdAt) }))" :key="message.id">
              <div v-if="index === 0 || message.date.diff(utilities.parseISODate(ticket.messages[index - 1].createdAt), 'minutes').minutes > 10" class="text-center text-muted small mt-3 mb-3">{{ utilities.formatDatetime(message.createdAt) }}</div>
              <div class="mb-1">
                <div v-if="index === 0 || message.sender.id !== ticket.messages[index - 1].sender.id" class="d-flex mb-1 mt-2" :class="message.sender.type === 'player' && message.sender.id === info.player.id ? 'justify-content-end' : 'justify-content-start'">
                  <PlayerView v-if="message.sender.type === 'player'" :player="message.sender"/>
                  <UserView v-if="message.sender.type === 'user'" :user="message.sender"/>
                </div>
                <div class="d-flex" :class="message.sender.type === 'player' && message.sender.id === info.player.id ? 'justify-content-end' : 'justify-content-start'">
                  <div class="card" :class="message.sender.type === 'player' && message.sender.id === info.player.id ? '' : ''" style="white-space: pre-wrap; max-width: 80%; word-break: break-all" :style="getMessageStyle(message, index, ticket)">
                    <div class="card-body p-3">
                      {{ message.content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div ref="underMessages"></div>
          </div>
          <div v-if="ticket.status.id !== 4 && !info.getModuleInfo(modules.support)?.banned" class="mt-3 mb-n3 position-sticky">
            <div class="card mb-n4" style="border-bottom-left-radius: 0; border-bottom-right-radius: 0; border-bottom: none">
              <div class="card-body p-3">
                <HttpFormView :endpoint="utilities.path('modules/support/tickets/@/messages', ticket.id)" method="post" @success="reload = true; emit('updated')">
                  <template v-slot:form="{ content, errors, submit, loading }">
                    <InputView type="textarea" :max="2000" :title="translate('message')" :errors="errors.content" v-model:value="content.content"/>
                    <div class="w-100 d-flex align-items-center justify-content-between mt-3">
                      <div>
                        <TargetedModalView v-if="ticket.status.id !== 4">
                          <template v-slot:target="{ show }">
                            <ButtonView type="normal" color="secondary" :disabled="content.content !== undefined && content.content.length > 0" @click="show">
                              <div class="d-flex align-items-center g-2">
                                <i class="fas fa-times-circle"></i>
                                <span>{{ translate('close') }}</span>
                              </div>
                            </ButtonView>
                          </template>
                          <template v-slot:body="{ hide }">
                            <HttpFormView :endpoint="utilities.path('modules/support/tickets/@/close', ticket.id)" method="post" @success="reload = true; emit('updated')">
                              <template v-slot:form="{ submit, loading }">
                                <div class="d-flex align-items-center g-4">
                                  <ButtonView type="normal" color="warning" class="w-100" @click="submit">
                                    {{ translate('close') }}
                                  </ButtonView>
                                  <ButtonView type="normal" color="secondary" class="w-100" @click="hide">
                                    {{ translate("cancel") }}
                                  </ButtonView>
                                </div>
                                <SpinnerView :active="loading" color="warning"/>
                              </template>
                            </HttpFormView>
                          </template>
                        </TargetedModalView>
                      </div>
                      <ButtonView type="normal" color="primary" :disabled="!content.content || content.content.length === 0" @click="submit(); content.content = ''">
                        <div class="d-flex align-items-center g-2">
                          <i class="fas fa-paper-plane"></i>
                          <span>{{ translate("send") }}</span>
                        </div>
                      </ButtonView>
                    </div>
                    <SpinnerView :active="loading" color="primary"/>
                  </template>
                </HttpFormView>
              </div>
            </div>
          </div>
        </div>
      </template>
    </HttpContentView>
  </div>
</template>

<script setup>
import {
  InputView,
  HttpContentView,
  HttpFormView,
  ButtonView,
  utilities,
  TargetedModalView,
  SpinnerView,
} from "@niftybit/vue";
import PlayerView from "@/components/other/PlayerView.vue";
import info from "@/info";
import UserView from "@/components/other/UserView.vue";
import {nextTick, onMounted, ref} from "vue";
import {modules} from "@/systems/modules/modules";

defineProps({
  ticketId: String
});

const emit = defineEmits(["updated"]);

const reload = ref(false);

const underMessages = ref(undefined);

onMounted(() => scrollToBottom());

function scrollToBottom() {
  nextTick(() => underMessages.value?.scrollIntoView({ behavior: "smooth" }));
}

function getMessageStyle(message, index, ticket) {
  if (message.sender.type === "player" && message.sender.id === info.player.id) {
    if (index === 0) {
      if (ticket.messages.length === 1 || ticket.messages[1].sender.id !== message.sender.id) return {};
      return { "border-bottom-right-radius": "0.3rem" };
    } else {
      if (ticket.messages.length > (index + 1)) {
        if (ticket.messages[index + 1].sender.id === message.sender.id) {
          if (ticket.messages[index - 1].sender.id === message.sender.id) return { "border-bottom-right-radius": "0.3rem", "border-top-right-radius": "0.3rem" };
          return { "border-bottom-right-radius": "0.3rem" };
        } else {
          if (ticket.messages[index - 1].sender.id === message.sender.id) return { "border-top-right-radius": "0.3rem" };
        }
      } else {
        if (ticket.messages[index - 1].sender.id === message.sender.id) return { "border-top-right-radius": "0.3rem" };
      }
    }
  } else {
    if (index === 0) {
      if (ticket.messages.length === 1 || ticket.messages[1].sender.id !== message.sender.id) return {};
      return { "border-bottom-left-radius": "0.3rem" };
    } else {
      if (ticket.messages.length > (index + 1)) {
        if (ticket.messages[index + 1].sender.id === message.sender.id) {
          if (ticket.messages[index - 1].sender.id === message.sender.id) return { "border-bottom-left-radius": "0.3rem", "border-top-left-radius": "0.3rem" };
          return { "border-bottom-left-radius": "0.3rem" };
        } else {
          if (ticket.messages[index - 1].sender.id === message.sender.id) return { "border-top-left-radius": "0.3rem" };
        }
      } else {
        if (ticket.messages[index - 1].sender.id === message.sender.id) return { "border-top-right-radius": "0.3rem" };
      }
    }
  }
}
</script>