import {modules} from "@/systems/modules/modules";
import authorization from "@/authorization";
import {RouterView} from "vue-router";
import AllowlistView from "@/systems/modules/allowlist/AllowlistView.vue";

export default {
    initialize() {

    },
    getRoutes: () => ([
        {
            name: "modules.allowlist",
            path: "allowlist",
            component: RouterView,
            meta: {
                authorize: () => authorization.moduleEnabled(modules.allowlist) && authorization.authenticated(),
            },
            redirect: { name: "modules.allowlist.home" },
            children: [
                {
                    name: "modules.allowlist.home",
                    path: "",
                    component: AllowlistView,
                    props: true
                },
                // {
                //     name: "modules.allowlist.applications",
                //     path: "applications",
                //     component: WikiArticlesView,
                //     props: true
                // },
                // {
                //     name: "modules.allowlist.applications.create",
                //     path: "applications/create",
                //     component: WikiArticleView,
                //     props: true
                // }
            ]
        },
    ])
};