<template>
  <div>
    <div class="d-flex justify-content-center my-5">
      <div class="d-flex text-center flex-column g-4" style="max-width: 300px">
        <h1><i class="fas fa-book text-primary"></i> {{ translate("wiki") }}</h1>
        <InputView type="text" :placeholder="translate('search')" v-model:value="searchQuery" size="large"/>
      </div>
    </div>
    <HttpContentView :endpoint="utilities.path('communities/@/modules/wiki/categories', communityId)">
      <template v-slot:content="{ data }">
        <h4>{{ translate("categories") }}</h4>
        <div class="d-flex align-items-center g-2 flex-wrap mb-4">
          <ButtonView v-for="category in data" :key="category.id" type="normal" color="secondary" @click="categorySlug === category.slug ? categorySlug = undefined : categorySlug = category.slug" :active="categorySlug === category.slug">
            <div class="d-flex align-items-center g-2">
              <i class="fas fa-circle" :style="{ color: category.color }"></i>
              <span>{{ category.name }}</span>
            </div>
          </ButtonView>
        </div>
      </template>
    </HttpContentView>
    <h4>{{ translate("articles") }}</h4>
    <HttpContentView :endpoint="utilities.path('communities/@/modules/wiki/articles/search', communityId)" :params="{ query: readySearchQuery, categorySlug }">
      <template v-slot:content="{ data }">
        <div class="row g-4">
          <div class="col-12 col-md-6 col-lg-4" v-for="article in data" :key="article.id">
            <RouterLink class="text-decoration-none text-foreground" :to="{ name: 'modules.wiki.article', params: { categorySlug: article.category.slug, articleSlug: article.slug }}">
              <div class="card clickable">
                <div class="card-body">
                  <div class="d-flex align-items-center g-2 small mb-2">
                    <i class="fas fa-circle" :style="{ color: article.category.color }"></i>
                    <span>{{ article.category.name }}</span>
                  </div>
                  <h3>{{ article.name }}</h3>
                  <div class="text-muted small">{{ utilities.formatDatetime(article.updatedAt ?? article.createdAt) }}</div>
                </div>
              </div>
            </RouterLink>
          </div>
        </div>
        <div v-if="data.length === 0">
          <div class="alert alert-secondary">
            {{ translate("no.results") }}
          </div>
        </div>
      </template>
    </HttpContentView>
  </div>
</template>

<script setup>
import {HttpContentView, utilities, InputView, ButtonView} from "@niftybit/vue";
import info from "@/info";
import {ref, watch} from "vue";

const categorySlug = utilities.routePathParam("categorySlug");

const communityId = info.community.id;

const searchQuery = utilities.routeQueryParam("query");

const readySearchQuery = ref("");

let searchTimeout = undefined;
watch(searchQuery, () => {
  if (searchTimeout) clearTimeout(searchTimeout);
  searchTimeout = setTimeout(() => {
    readySearchQuery.value = searchQuery.value;
  }, 200);
}, {
  immediate: true
});

</script>