<template>
  <div>
    <ButtonView type="normal" color="secondary" class="mb-4" active-class="" :route="{ name: 'modules.applications.applications' }">
      <div class="d-flex align-items-center g-2">
        <i class="fas fa-arrow-left fa-fw"></i>
        <span>{{ translate("applications") }}</span>
      </div>
    </ButtonView>
    <div v-if="info.getModuleInfo(modules.applications)?.banned">
      <div class="alert alert-warning">
        <div class="d-flex align-items-center g-3">
          <i class="fas fa-ban text-warning"></i>
          <span>{{ translate('applications.banned.explanation') }}</span>
        </div>
      </div>
    </div>
    <HttpFormView :endpoint="utilities.path('modules/applications/categories/@/applications', categoryId)" :content="{ answers: [] }" method="post" @success="router.push({ name: 'modules.applications.applications', query: { viewId: $event.id }})">
      <template v-slot:form="{ content, errors, submit, loading }">
        <div class="row g-4">
          <div class="col-12 col-md-6 col-lg-4" v-for="category in info.getModuleInfo(modules.applications)?.applicableCategories" :key="category.id">
            <div class="card clickable" :class="{ 'border-primary': categoryId === category.id }" @click="categoryId = category.id; nextTick(() => formElement.scrollIntoView())">
              <div class="card-body">
                <h3 class="d-flex align-items-center g-2 mb-0">
                  <i class="fas fa-tag" :style="{ color: category.color }"></i> <span class="fw-bold">{{ category.name }}</span>
                </h3>
                <div v-if="category.description" class="mt-3 small">{{ category.description }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-4" v-if="categoryId" ref="formElement">
          <div class="card-body">
            <HttpContentView :endpoint="utilities.path('modules/applications/categories/@/questions', categoryId)">
              <template v-slot:content="{ data: questions }">
                <div class="d-flex flex-column g-3">
                  <div v-for="(question, index) in questions" :key="question.id">
                    <InputView type="textarea" :max="1000" :title="question.content" :disabled="loading" :errors="errors?.answers ? errors.answers[index]?.content : undefined" required :value="content.answers.find(item => item.questionId === question.id)?.content" @update:value="content.answers.find(item => item.questionId === question.id) ? content.answers.find(item => item.questionId === question.id).content = $event : content.answers.push({ questionId: question.id, content: $event })"/>
                  </div>
                  <ButtonView type="normal" color="primary" class="w-100 mt-2" @click="submit" :disabled="!content.answers || content.answers.length < questions.length">
                    {{ translate("submit") }}
                    <SpinnerView color="primary" :active="loading"/>
                  </ButtonView>
                </div>
              </template>
            </HttpContentView>
          </div>
        </div>
        <SpinnerView :active="loading" color="primary"/>
      </template>
    </HttpFormView>
  </div>
</template>

<script setup>
import {ButtonView, SpinnerView, InputView, HttpFormView, HttpContentView, utilities} from "@niftybit/vue";
import info from "@/info";
import {modules} from "@/systems/modules/modules";
import {useRouter} from "vue-router";
import {nextTick, ref} from "vue";

const categoryId = ref(undefined);

const router = useRouter();

const formElement = ref(undefined);

</script>