import info from "@/info";
import localization from "@/localization";
import notifications from "@/systems/notifications/notifications";
import {reactive} from "vue";

export default reactive({
  initialized: false,
  async initialize() {
    try {
      await info.initialize();
    } catch (error) {
      if (!error.response || error.response.status !== 404) {
        throw error;
      }
      return;
    }
    if (localization.browserLanguage() && !info.community.language) {
      await localization.updateSessionLanguage(localization.browserLanguage());
    }
    await localization.reloadTranslations();
    notifications.initialize();
    this.initialized = true;
  }
});