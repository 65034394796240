import router from "@/router";

export default {
    isRouteAuthorized(route) {
        let resolved = router.resolve(route);
        let item = resolved.matched[resolved.matched.length - 1];
        if (!item.meta) return true;
        if (item.meta.authorize !== undefined && !item.meta.authorize(resolved)) return false;
        return true;
    },
};