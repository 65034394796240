export default {
    listeners: {},
    on(eventName, callback) {
        if (this.listeners[eventName] === undefined || this.listeners === null) {
            this.listeners[eventName] = [];
        }
        if (this.listeners[eventName].indexOf(callback) >= 0) return;
        this.listeners[eventName].push(callback);
    },
    unsubscribe(eventName, callback) {
        if (this.listeners[eventName] === undefined || this.listeners === null) return;
        const index = this.listeners[eventName].indexOf(callback);
        if (index >= 0) this.listeners[eventName].splice(index, 1);
    },
    emit(eventName, data) {
        if (this.listeners[eventName] === undefined || this.listeners === null) return;
        this.listeners[eventName].forEach(listener => listener(data));
    }
}