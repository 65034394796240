import {modules} from "@/systems/modules/modules";
import authorization from "@/authorization";
import ApplicationsView from "@/systems/modules/applications/ApplicationsView.vue";
import {RouterView} from "vue-router";
import CreateApplicationView from "@/systems/modules/applications/CreateApplicationView.vue";

export default {
    initialize() {

    },
    getRoutes: () => ([
        {
            name: "modules.applications",
            path: "applications",
            component: RouterView,
            meta: {
                authorize: () => authorization.moduleEnabled(modules.applications) && authorization.authenticated(),
            },
            redirect: { name: "modules.applications.applications" },
            children: [
                {
                    name: "modules.applications.applications",
                    path: "",
                    component: ApplicationsView
                },
                {
                    name: "modules.applications.applications.create",
                    path: "create",
                    component: CreateApplicationView
                },
            ]
        },
    ])
};