import {state} from "@/state";
import {io} from "socket.io-client";
import eventBus from "@/eventBus";

export default {
    install() {
        state.plugins.socket = {
            io: undefined,
            connect() {
                if (this.io) {
                    this.io.disconnect();
                    this.io = undefined;
                }
                this.io = io("https://" + location.host + "/frontend", { withCredentials: true, path: "/beacon/socket/" });
                this.io.on("vactar:event", data => {
                    eventBus.emit("socket:event", data);
                });
            },
            disconnect() {
                if (this.io && this.io.connected) this.io.disconnect();
                this.io = undefined;
            },
            emit(event, data) {
                if (this.io && this.io.connected) {
                    this.io.emit(event, data);
                } else {
                    console.error("Cannot emit " + event + " to socket (not connected).")
                }
            },
            isConnected() {
                return this.io && this.io.connected;
            }
        };
        eventBus.on("login", () => {
            state.plugins.socket.disconnect();
            state.plugins.socket.connect();
        });
        eventBus.on("logout", () => {
            state.plugins.socket.disconnect();
            state.plugins.socket.connect();
        });
        eventBus.on("info.loaded", () => {
           if (!state.plugins.socket.isConnected()) {
               state.plugins.socket.connect();
           }
        });
    }
}
