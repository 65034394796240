import PlayerProfileView from "@/systems/modules/players/PlayerProfileView.vue";
import info from "@/info";

export default {
    initialize() {

    },
    getRoutes: () => ([
        {
            name: "players.me",
            path: "players/me",
            component: PlayerProfileView,
            meta: {
                authorize: () => info.player != null
            }
        }
    ])
};