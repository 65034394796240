<template>
  <div>
    <HttpContentView :endpoint="utilities.path('communities/@/modules/wiki/categories/@/articles/@', communityId, categorySlug, articleSlug)">
      <template v-slot:content="{ data }">
        <div class="d-flex align-items-center justify-content-between g-3 mb-3">
          <ButtonView type="normal" color="secondary" active-class="" :route="state.lastRoute.name === 'modules.wiki.home' ? state.lastRoute : { name: 'modules.wiki.home' }">
            <div class="d-flex align-items-center g-2">
              <i class="fas fa-arrow-left fa-fw"></i>
              {{ translate("wiki") }}
            </div>
          </ButtonView>
          <div class="d-flex align-items-center g-2">
            <i class="fas fa-circle" :style="{ color: data.category.color }"></i>
            <span>{{ data.category.name }}</span>
          </div>
        </div>
        <h2 class="mb-3 d-flex align-items-center g-2">
          {{ data.name }}
        </h2>
        <div class="card">
          <div class="card-body">
            <RichTextView :features="features" :content="data.content"/>
          </div>
        </div>
        <div class="text-end mt-3 me-3">
          {{ utilities.formatDatetime(data.updatedAt ?? data.createdAt) }}
        </div>
      </template>
    </HttpContentView>
  </div>
</template>

<script setup>
import {HttpContentView, richTextFeatures, utilities, ButtonView, RichTextView} from "@niftybit/vue";
import info from "@/info";
import {useRouter} from "vue-router";
import {state} from "../../../state";

defineProps({
  categorySlug: String,
  articleSlug: String
});

const router = useRouter();

const communityId = info.community?.id;

const features = [
  richTextFeatures.paragraph.create(),
  richTextFeatures.text.create(),
  richTextFeatures.hardBreak.create(),
  richTextFeatures.heading.create(),
  richTextFeatures.textStyle.create(),
  richTextFeatures.codeBlock.create(),
  richTextFeatures.bulletList.create(),
  richTextFeatures.orderedList.create(),
  richTextFeatures.infoBox.create(),
  richTextFeatures.blockQuote.create(),
  richTextFeatures.textAlign.create(),
  richTextFeatures.code.create(),
  richTextFeatures.strikethrough.create(),
  richTextFeatures.bold.create(),
  richTextFeatures.underline.create(),
  richTextFeatures.italic.create(),
  richTextFeatures.link.create(),
  richTextFeatures.videoEmbed.create(),
  richTextFeatures.image.create(),
];

</script>