import {createRouter, createWebHistory} from 'vue-router'
import NotFoundView from "@/components/NotFoundView.vue";
import info from "@/info";
import AuthenticationView from "@/systems/authentication/AuthenticationView.vue";
import eventBus from "@/eventBus";
import HomeView from "@/systems/core/HomeView.vue";
import blog from "@/systems/modules/blog/blog";
import wiki from "@/systems/modules/wiki/wiki";
import allowlist from "@/systems/modules/allowlist/allowlist";
import punishments from "@/systems/modules/punishments/punishments";
import support from "@/systems/modules/support/support";
import applications from "@/systems/modules/applications/applications";
import players from "@/systems/modules/players/players";
import {state} from "@/state";

const routes = [
  {
    path: "/",
    children: [
      {
        path: "",
        component: HomeView
      },
      ...blog.getRoutes(),
      ...wiki.getRoutes(),
      ...allowlist.getRoutes(),
      ...punishments.getRoutes(),
      ...support.getRoutes(),
      ...applications.getRoutes(),
      ...players.getRoutes(),
    ]
  },
  {
    name: "authentication",
    path: "/authentication",
    component: AuthenticationView
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundView
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

let requestedPath = window.location.pathname + window.location.search;
router.beforeEach((to, from, next) => {
  state.lastRoute = from;
  if (info.player && to.name === "authentication") {
    next("/");
    return;
  }
  if (to.name === "authentication") {
    next();
    return;
  }
  if (!info.community) return;
  if (!to.meta?.authorize || to.meta.authorize(to)) {
    next();
  } else {
    next({ name: "authentication" });
  }
});

eventBus.on("login", () => {
  if (requestedPath !== undefined && router.resolve({ path: requestedPath })?.name !== "authentication") {
    router.push(requestedPath);
  }
  requestedPath = undefined;
});

eventBus.on("info.loaded", () => {
  if (requestedPath !== undefined) {
    const resolved = router.resolve({ path: requestedPath });
    if (resolved && (!resolved.meta?.authorize || resolved.meta.authorize(resolved))) {
      router.push(requestedPath);
      requestedPath = undefined;
    } else {
      router.push("/");
    }
  }
});

export default router
