import axios from "axios";
import {toasts} from "@niftybit/vue";
import {reactive} from "vue";
import info from "@/info";

export default reactive({
    translations: {},
    translate(key) {
        if (!key || key.length < 1) return key;
        let item = this.translations[key];
        return item ? item : key;
    },
    async reloadTranslations() {
        try {
            const response = await axios.get("info/translations");
            this.translations = response.data;
        } catch (error) {
            toasts.create({
                color: "danger",
                content: "Failed to load translations (status " + error.response?.status + ")."
            });
        }
    },
    async updateSessionLanguage(code) {
        await axios.post("session/language", { code: code });
        info.community.language = code;
    },
    browserLanguage() {
        return navigator.languages.length > 0 ? navigator.languages[0].split(/[-_]/)[0].toLowerCase() : undefined;
    }
});