<template>
  <div>
    <div ref="container">
      <div v-if="notifications.list.length > 0" class="list-group">
        <div v-for="notification in notifications.list" :key="notification.id" class="list-group-item" :class="{ 'list-group-item-action': notification.route !== undefined && notifications.routeExists(notification.route) || !notification.read }" @click="clicked(notification)">
          <NotificationView :notification="notification"/>
        </div>
      </div>
      <ButtonView v-if="notifications.total > notifications.list.length" type="soft" color="primary" :disabled="notifications.loading" class="w-100 mt-4" @click="notifications.load()">
        {{ translate("notifications.load.more") }}
      </ButtonView>
    </div>
    <div v-if="notifications.list.length === 0" class="p-5">
      <div v-if="notifications.loading">
        <SpinnerView color="primary" active/>
      </div>
      <div v-else class="d-flex align-items-center justify-content-center g-3 flex-column">
        <i class="fa-duotone fa-folder-open text-primary" style="font-size: 3em"></i>
        <div>{{ translate("notifications.empty") }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {SpinnerView, ButtonView} from "@niftybit/vue";
import NotificationView from "@/systems/notifications/NotificationView.vue";
import notifications from "@/systems/notifications/notifications";
import {useRouter} from "vue-router";

const emit = defineEmits(["dismiss"]);

const router = useRouter();

function clicked(notification) {
  if (notification.route !== undefined && notifications.routeExists(notification.route)) {
    router.push(notification.route);
    notifications.read(notification.id);
    emit("dismiss");
  } else if (!notification.read) {
    notifications.read(notification.id);
  }
}

</script>