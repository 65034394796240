<template>
  <div>
    <div class="">
      <div class="">
        <HttpContentView endpoint="home-page">
          <template v-slot:content="{ data }">
            <RichTextView :content="data.content" :features="contentFeatures"/>
          </template>
        </HttpContentView>
      </div>
    </div>
  </div>
</template>

<script setup>
import {HttpContentView, richTextFeatures, RichTextView} from "@niftybit/vue";

const contentFeatures = [
  richTextFeatures.paragraph.create(),
  richTextFeatures.text.create(),
  richTextFeatures.hardBreak.create(),
  richTextFeatures.heading.create(),
  richTextFeatures.textStyle.create(),
  richTextFeatures.codeBlock.create(),
  richTextFeatures.bulletList.create(),
  richTextFeatures.orderedList.create(),
  richTextFeatures.infoBox.create(),
  richTextFeatures.blockQuote.create(),
  richTextFeatures.textAlign.create(),
  richTextFeatures.code.create(),
  richTextFeatures.strikethrough.create(),
  richTextFeatures.bold.create(),
  richTextFeatures.underline.create(),
  richTextFeatures.italic.create(),
  richTextFeatures.link.create(),
  richTextFeatures.videoEmbed.create(),
  richTextFeatures.image.create()
];

</script>