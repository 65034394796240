<template>
  <div>
    <div v-if="info.getModuleInfo(modules.support)?.banned">
      <div class="alert alert-warning mb-4">
        <div class="d-flex align-items-center g-3">
          <i class="fas fa-ban text-warning"></i>
          <span>{{ translate('support.banned.explanation') }}</span>
        </div>
      </div>
    </div>
    <div>
      <HttpContentView endpoint="modules/support/tickets/categories">
        <template v-slot:content="{ data: categories }">
          <div class="d-flex align-items-center flex-wrap g-2 mb-3" v-if="categories.length > 0">
            <div v-for="category in categories" :key="category.id">
              <ButtonView type="normal" color="secondary" size="small" :active="categoryId === category.id" @click="categoryId === category.id ? categoryId = undefined : categoryId = category.id">
                <div class="d-flex align-items-center">
                  <i class="fas fa-tag" :style="{ color: category.color }"></i>
                  <span class="ms-2">{{ category.name }}</span>
                </div>
              </ButtonView>
            </div>
          </div>
        </template>
      </HttpContentView>
    </div>
    <CollectionView searchable endpoint="modules/support/tickets" :params="{ categoryId }" v-model:reload="reload">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between g-4">
          <h4 class="mb-0">{{ translate("tickets") }}</h4>
          <ButtonView v-if="!info.getModuleInfo(modules.support)?.banned" type="normal" color="primary" :route="{ name: 'modules.support.tickets.create' }">
            {{ translate("create") }}
          </ButtonView>
        </div>
      </template>
      <template v-slot:content="{ items }">
        <div class="card">
          <div class="table-responsive">
            <div class="table">
              <div class="table-header">
                <div class="table-header-cell">{{ translate("subject") }}</div>
                <div class="table-header-cell">{{ translate("category") }}</div>
                <div class="table-header-cell">{{ translate("status") }}</div>
                <div class="table-header-cell">{{ translate("created_at") }}</div>
              </div>
              <div class="table-body">
                <a class="table-row" v-for="item in items" :key="item.id" @click="viewId = item.id">
                  <div class="table-column">
                    {{ item.subject }}
                  </div>
                  <div class="table-column">
                    <div class="d-flex align-items-center">
                      <i class="fas fa-tag fa-lg me-2" :style="{ color: item.category.color }"></i> <span>{{ item.category.name }}</span>
                    </div>
                  </div>
                  <div class="table-column" :class="{ 'text-danger': item.status.id === 3, 'text-success': item.status.id === 2, 'text-info': item.status.id === 1 }">{{ item.status.title }}</div>
                  <div class="table-column">{{ utilities.formatDatetime(item.createdAt) }}</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CollectionView>
    <ModalView size="large" :active="viewId !== undefined" @update:active="viewId = undefined">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between g-4">
          <h3 class="mb-0">{{ translate("ticket") }}</h3>
          <ModalCloseButtonView @click="viewId = undefined"/>
        </div>
      </template>
      <template v-slot:body>
        <TicketView :ticket-id="viewId" @updated="reload = true"/>
      </template>
    </ModalView>
  </div>
</template>

<script setup>
import {
  CollectionView,
  utilities,
  ButtonView,
  ModalView,
  ModalCloseButtonView,
  HttpContentView
} from "@niftybit/vue";
import {ref} from "vue";
import TicketView from "@/systems/modules/support/TicketView.vue";
import info from "@/info";
import {modules} from "@/systems/modules/modules";

const reload = ref(false);

const categoryId = ref(undefined);
const viewId = utilities.routeQueryParam("viewId");

</script>