import {modules} from "@/systems/modules/modules";
import authorization from "@/authorization";
import {RouterView} from "vue-router";
import WikiView from "@/systems/modules/wiki/WikiView.vue";
import WikiArticleView from "@/systems/modules/wiki/WikiArticleView.vue";

export default {
    initialize() {

    },
    getRoutes: () => ([
        {
            name: "modules.wiki",
            path: "wiki",
            component: RouterView,
            meta: {
                authorize: () => authorization.moduleEnabled(modules.wiki),
            },
            redirect: { name: "modules.wiki.home" },
            children: [
                {
                    name: "modules.wiki.home",
                    path: ":categorySlug?",
                    component: WikiView,
                    props: true
                },
                {
                    name: "modules.wiki.article",
                    path: ":categorySlug/:articleSlug",
                    component: WikiArticleView,
                    props: true
                }
            ]
        },
    ])
};