<template>
  <div class="d-flex justify-content-center align-items-center h-100 mt-4">
    <div class="card" style="width: min(450px, 100%)">
      <div class="card-body text-center">
        <div class="mt-3 mb-5">
          <img v-if="info.community.logoUrl" :src="info.community.logoUrl" class="rounded-1" style="max-height: 4em; width: 4em"/>
          <h2 class="mt-4 mb-0">{{ info.community.name }}</h2>
        </div>
        <div>
          <HttpFormView endpoint="authentication/code" method="post" @success="info.reload(); router.replace('/')">
            <template v-slot:form="{ content, errors, submit, loading }">
              <div class="d-flex flex-column g-4">
                <div>
                  <InputView type="text" :title="translate('auth.code')" :errors="errors.code" v-model:value="content.code" :max="4" required/>
                  <div class="mt-2" style="font-size: 0.85em">
                    {{ translate("player.auth.code.description") }}
                  </div>
                </div>

                <div class="text-muted text-center" style="font-size: 0.85em;">
                  {{ translate("player.auth.consent.text") }}
                </div>

                <ButtonView type="normal" color="primary" :disabled="!content.code || loading" class="w-100" @click="submit">
                  {{ translate("continue") }}
                </ButtonView>

                <ButtonView v-if="info.community?.authentication?.domain && info.community.authentication.provider" type="normal" color="secondary" :style="info.community.authentication.provider.buttonStyle" class="w-100" @click="startSocialLogin">
                    <div class="d-flex justify-content-center align-items-center g-2">
                        <i :class="info.community.authentication.provider.icon" style="font-size: 1.1em"></i>
                        <span class="text-capitalize">{{ info.community.authentication.provider.title }}</span>
                    </div>
                </ButtonView>

                <a class="btn btn-link btn-foreground mt-2" href="https://vactar.io">
                  <div class="d-flex justify-content-center align-items-center g-2">
                    <img src="@/assets/img/vactar.png" width="24"/>
                    <span>Vactar</span>
                  </div>
                </a>

                <div class="d-flex justify-content-center g-1 align-items-center" style="word-break: break-all">
                  <ButtonView type="shaded" color="primary" size="small" @click="openTerms">
                    {{ translate("terms.of.service") }}
                  </ButtonView>
                  <ButtonView type="shaded" color="primary" size="small" @click="openPrivacy">
                    {{ translate("privacy.policy") }}
                  </ButtonView>
                </div>
                <SpinnerView :active="loading" color="primary"/>
              </div>
            </template>
          </HttpFormView>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {InputView, HttpFormView, ButtonView, SpinnerView} from "@niftybit/vue";
import info from "@/info";
import {useRouter} from "vue-router";

const router = useRouter();

function openTerms() {
  window.open('https://vactar.io/policies/terms', '_blank')
}

function openPrivacy() {
  window.open('https://vactar.io/policies/privacy', '_blank');
}

function startSocialLogin() {
  window.open("https://" + info.community.authentication.domain + "/api/frontend/communities/" + info.community.id + "/provider/redirect", "_self");
}

</script>